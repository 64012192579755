import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import useFetch from "../../useFetch";
import CoachingCard from "./CoachingCard";

import GroupedSelect from "./GroupSelectForm";
import Spinner from "../../images/spinner2.gif";
import Cookies from 'js-cookie';
import coachingcardimage1 from "../../images/cards/1.jpg";
import coachingcardimage2 from "../../images/cards/2.jpg";
import coachingcardimage3 from "../../images/cards/3.jpg";
import coachingcardimage4 from "../../images/cards/4.jpg";
import coachingcardimage5 from "../../images/cards/5.jpg";
import coachingcardimage6 from "../../images/cards/6.jpg";
import coachingcardimage7 from "../../images/cards/7.jpg";
import coachingcardimage8 from "../../images/cards/8.jpg";
import coachingcardimage9 from "../../images/cards/9.jpg";
import coachingcardimage10 from "../../images/cards/10.jpg";
import coachingcardimage11 from "../../images/cards/11.jpg";
import coachingcardimage12 from "../../images/cards/12.jpg";
import coachingcardimage13 from "../../images/cards/13.jpg";
import coachingcardimage14 from "../../images/cards/14.jpg";
import coachingcardimage15 from "../../images/cards/15.jpg";
import coachingcardimage16 from "../../images/cards/16.jpg";
import coachingcardimage17 from "../../images/cards/17.jpg";
import coachingcardimage18 from "../../images/cards/18.jpg";
import coachingcardimage19 from "../../images/cards/19.jpg";
import coachingcardimage20 from "../../images/cards/20.jpg";


function CoachingCardContainer(props) {



  let CookieValue = Cookies.get('coachingcards.ie');
  // console.log("cookie value is: "+CookieValue);
  if (CookieValue === null) {
    Cookies.set('coachingcards.ie', '1', { expires: 365 });
    CookieValue = 1;
  }

  if (isNaN(CookieValue)) {
    // console.log("Cookie was NAN");
    CookieValue = 1;
    Cookies.set('coachingcards.ie', '1', { expires: 365 });
  }


  const [optionSelected, setOptionSelected] = React.useState(parseInt(CookieValue));


  let bookmarked = '';



  // Select a random file from the cards directory
  var fileToShow = coachingcardimage1;
  const min = 1;
  const max = 20;
  const random = Math.random() * (max - min) + min;
  const number = Math.round(random);
  if (number === 1) { fileToShow = coachingcardimage1; }
  if (number === 2) { fileToShow = coachingcardimage2; }
  if (number === 3) { fileToShow = coachingcardimage3; }
  if (number === 4) { fileToShow = coachingcardimage4; }
  if (number === 5) { fileToShow = coachingcardimage5; }
  if (number === 6) { fileToShow = coachingcardimage6; }
  if (number === 7) { fileToShow = coachingcardimage7; }
  if (number === 8) { fileToShow = coachingcardimage8; }
  if (number === 9) { fileToShow = coachingcardimage9; }
  if (number === 10) { fileToShow = coachingcardimage10; }
  if (number === 11) { fileToShow = coachingcardimage11; }
  if (number === 12) { fileToShow = coachingcardimage12; }
  if (number === 13) { fileToShow = coachingcardimage13; }
  if (number === 14) { fileToShow = coachingcardimage14; }
  if (number === 15) { fileToShow = coachingcardimage15; }
  if (number === 16) { fileToShow = coachingcardimage16; }
  if (number === 17) { fileToShow = coachingcardimage17; }
  if (number === 18) { fileToShow = coachingcardimage18; }
  if (number === 19) { fileToShow = coachingcardimage19; }
  if (number === 20) { fileToShow = coachingcardimage20; }


  // console.log("Coaching Card is being loaded");
  const handleClick = () => {
    // setCardsLoaded (cardsLoaded+1);
    window.location.reload(false);
  };



  //get the location of the json for the cards
  const host = "http://" + window.location.host + "/SampleCards.txt";
  const { data, loading, error } = useFetch(host);
  if (loading) return <div align="centre"><img src={Spinner}
    style={{ width: '100px', margin: 'auto', marginTop: '50px', display: 'block' }}
    alt="Loading..." /></div>;
  if (error) console.log(error);

  var groupArray = null;
  var optionsArray = null;


  //console.log('Option Selected:' + optionSelected);
  try {
    for (const groupIterator of data.groups) {
      for (const optionIterator of groupIterator.options) {
        if (parseInt(optionIterator.optionsId) === parseInt(optionSelected)) {
          //console.log("Found a match ");
          groupArray = groupIterator;
          optionsArray = optionIterator
        }
      }
    }
  } catch (error) {
    console.log(error);
  }

  // console.log('Group name Selected:' + groupArray.name);
  //console.log('Option name Selected:' + optionsArray.name);
  var card = optionsArray.cards[Math.floor(Math.random() * optionsArray.cards.length)];

  let CookieValue2 = [];
  try {
    //console.log(Cookies.get('Coachingcards.ie - Bookmarked Cards'));
    CookieValue2 = Cookies.get('Coachingcards.ie - Bookmarked Cards').split(',');
    //console.log(CookieValue2);
  }
  catch (e) {
    //console.log('woops');
    const tempArray = [10001, 10002];
    Cookies.set('Coachingcards.ie - Bookmarked Cards', tempArray, { expires: 365 });
  }
  finally {

  }

  //console.log('looking for card'+card.cardid);
  //console.log('in array: '+CookieValue2);
  //console.log('index: '+CookieValue2.indexOf(''+ card.cardid));

  if (CookieValue2.indexOf('' + card.cardid) > -1) {
    //  console.log('found');
    bookmarked = 'primary';

  }

  const changeCoachingOptionHandler = (option) => {
    setOptionSelected(option);
    Cookies.set('coachingcards.ie', String(option), { expires: 365 });
    //  console.log('CoachingCardContainer got a value from GroupedSelect of '+option);
    window.location.reload(false);

  }

  return (
    <>
      <div className="App">

        <Typography variant='h7' display={'none'} align='center' colour="textSecondary" paragraph gutterBottom>Welcome to caching cards. A place to expand your list of coaching questions. </Typography>
        <Container  >
          <div align="center">
            <GroupedSelect optionSelected={optionSelected} onChangeSelection={changeCoachingOptionHandler} />
            <CoachingCard bookmarked={bookmarked} cardId={card.cardid} cardTitle={groupArray.name} cardSubTitle={optionsArray.name} cardQuestion={card.question} cardDetail={card.whentouse} cardImage={fileToShow} twitter={card.twitterlink} website={card.websitelink} />


          </div>
        </Container>
      </div>
      <div className='Button-Load'>
        <Button variant="contained" onClick={handleClick}>Load Another</Button>


      </div>
    </>
  );
}

export default CoachingCardContainer; 