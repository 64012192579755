
import './App.css';
import React from 'react';
import CoachingCardContainer from './pages/coachingcard/CoachingCardContainer';
import PageLayout from './components/PageLayout';


function App() {
  return (
    <PageLayout>
        <CoachingCardContainer />
    </PageLayout>
  );
}
export default App;
