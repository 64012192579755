
import '../App.css';
import React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";
import TagManager from "react-gtm-module";
import TopNav from './topnav/TopNav';
import Footer from './footer/Footer';



const tagManagerArgs = {
  gtmId: "G-JEVQDMJ1GG"
}

TagManager.initialize(tagManagerArgs)

export default function PageLayout(props){

  try {
    return(
      <>
      <HelmetProvider>
      <Helmet>
        <title>Coaching Cards | Cards , Coaching , Parent, Team, Personal, Executive,Performance</title>
        <meta name="description" content="Coaching Cards  | Cards , Coaching , Parent, Team, Personal, Executive, Performance,Agile" />
      </Helmet>
    </HelmetProvider>
    <script type="text/javascript" src="https://coachingcards.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=d7acf05a"></script>
    <TopNav />
    <main className="Main" >
    <div className='TopPad' />
      {props.children}
      <div style={{paddingTop:"10rem"}}></div>
    </main>      
    <Footer />  
    </>      
  );
  } catch (error) {
    return <p>Sorry, an error occurred.</p>;
  }

}