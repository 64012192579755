import React, { useEffect, useState } from 'react';
import TinderCard from './TinderCard'; // assuming this is the path to your TinderCard component

const options = [
  {
    title: 'Card 1',
    id: '1',
    description: 'This is card 1',
    imageUrl: 'http://www.coachingcards.ie/images/cards/1.jpg',
  },
  {
    title: 'Card 2',
    id: '2',
    description: 'This is card 2',
    imageUrl: 'https://example.com/card2.jpg',
  },
  {
    title: 'Card 3',
    id: '3',
    description: 'This is card 3',
    imageUrl: 'https://example.com/card1.jpg',
  },
  {
    title: 'Card 4',
    id: '4',
    description: 'This is card 4',
    imageUrl: 'https://example.com/card2.jpg',
  },
  {
    title: 'Card 5',
    id: '5',
    description: 'This is card 5',
    imageUrl: 'https://example.com/card1.jpg',
  },
  {
    title: 'Card 6',
    id: '6',
    description: 'This is card 6',
    imageUrl: 'https://example.com/card6.jpg',
  },
  // add more cards as needed
];





const SwipeCard = () => {

    const [bookmarkedCards, setBookmarkedCards] = useState([]);

      // Retrieve bookmarkedCards array from sessionStorage on component load
  useEffect(() => {
    const bookmarkedCardsFromLocalStorage = localStorage.getItem('bookmarkedCards');
    if (bookmarkedCardsFromLocalStorage) {
    console.log('cards local storage is: '+JSON.parse(bookmarkedCardsFromLocalStorage));
      setBookmarkedCards(JSON.parse(bookmarkedCardsFromLocalStorage));
    }
  }, []);

  // Function to handle adding a bookmark
  const onBookmark = (cardId) => {
    // Check if cardId is already bookmarked
    const isBookmarked = bookmarkedCards.includes(cardId);
    // If cardId is not bookmarked, add it to the bookmarkedCards array
    if (!isBookmarked) {
      setBookmarkedCards([...bookmarkedCards, cardId]);
      localStorage.setItem('bookmarkedCards',JSON.stringify([...bookmarkedCards, cardId]));
    }
    // If cardId is already bookmarked, remove it from the bookmarkedCards array
    else {
      setBookmarkedCards(bookmarkedCards.filter(id => id !== cardId));
      localStorage.setItem('bookmarkedCards',JSON.stringify(bookmarkedCards.filter(id => id !== cardId)));
    }
    
    
  }
    

  return (
    <div>
      {/* Render TinderCard component and pass options as a prop */}

      <TinderCard options={options} bookmarkedCards={bookmarkedCards} onBookmark={onBookmark}/>
    </div>
  );
};

export default SwipeCard;