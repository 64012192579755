import { useState, useEffect, useRef } from "react";

// This custom hook centralizes and streamlines handling of HTTP calls
export default function useFetch(url, init) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const prevInit = useRef();
  const prevUrl = useRef();

  //console.log('Use Fetch is executing for '+ url);

  useEffect(() => {
    // Only refetch if url or init params change.
    if (prevUrl.current === url && prevInit.current === init) return;
    prevUrl.current = url;
    prevInit.current = init;

  fetch(url , init)
  .then(response => {
    if (response.ok) return response.json();
    throw response;
  })
  .then(data => setData(data))
  .catch(err => {
    console.error('Use Fetch had an error: ' + err);
    setError(err);
  })
  .finally(() => setLoading(false));
  }, [init, url]);

 // console.log('Use Fetch is Completed');
  return { data, loading, error };
}

