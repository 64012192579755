import React from 'react';
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import PageLayout from "../components/PageLayout";
import GroupedSelect from "./coachingcard/GroupSelectForm";






function AddCard() {


    return (
        <PageLayout>
            <div align="center">
                <Card sx={{ maxWidth: 450 }} className='Card'>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Add a new Card
                        </Typography>
                        <GroupedSelect />
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <TextField
                                    id="newModel"
                                    disabled="true"
                                    name="newModal"
                                    label="New Coaching Model Name (if applicable)"
                                    fullWidth
                                    autoComplete="New Coaching Model Name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="question"
                                    disabled="true"
                                    name="question"
                                    label="Coaching Question"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    autoComplete="Coaching Question"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="twitter"
                                    name="twitter"
                                    disabled="true"
                                    label="Your @Twitter ID"
                                    fullWidth
                                    autoComplete="Your @Twitter ID"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="website"
                                    disabled="true"
                                    name="website"
                                    label="Your Website"
                                    fullWidth
                                    autoComplete="Your Website"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" disabled="true" >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>

            </div>


        </PageLayout>

    );
}

export default AddCard;