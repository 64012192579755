import React, {  useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CardContainer = styled(Card)({
  position: 'relative',
  width: 300,
  height: 400,
  margin: '0 auto',
  marginTop: 100,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: '0.3s',
  cursor: 'grab',
});

const CardContentContainer = styled(CardContent)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#ddd',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 10,
});

const TinderCard = ({ options, bookmarkedCards, onBookmark }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [swipedCards, setSwipedCards] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
 

  const handleSwipeRight = () => {
    console.log('swR');
    setSwipedCards([...swipedCards, currentCardIndex]);
    const newIndex = Math.floor(Math.random() * options.length);
    setCurrentCardIndex(newIndex);
  };

  const handleSwipeLeft = () => {
    console.log('swL');
    setSwipedCards(swipedCards.slice(0, swipedCards.length - 1));
    setCurrentCardIndex(swipedCards[swipedCards.length - 1]);
  };

  const handleCardMouseDown = (event) => {
    event.preventDefault();
    document.addEventListener('mousemove', handleCardMouseMove);
    document.addEventListener('mouseup', handleCardMouseUp);
    document.addEventListener('mouseleave', handleCardMouseUp);
    document.body.style.cursor = 'grabbing';
  };

  const handleCardTouchStart = (event) => {
    const card = document.getElementById('card-container');
    const touch = event.touches[0];
    const startX = touch.clientX;
    const startY = touch.clientY;
  
    const handleCardTouchMove = (event) => {
      const touch = event.touches[0];
      const deltaX = touch.clientX - startX;
      const deltaY = touch.clientY - startY;
      card.style.transform = `translateX(${deltaX}px) translateY(${deltaY}px) rotate(${(deltaX / card.offsetWidth) * 15}deg)`;
  
      if (deltaX > 100) {
        handleSwipeRight();
      } else if (deltaX < -100) {
        handleSwipeLeft();
      }
    };
  
    const handleCardTouchEnd = () => {
      document.removeEventListener('touchmove', handleCardTouchMove);
      document.removeEventListener('touchend', handleCardTouchEnd);
      card.style.transform = '';
    };
  
    document.addEventListener('touchmove', handleCardTouchMove);
    document.addEventListener('touchend', handleCardTouchEnd);
  };

  const handleCardMouseMove = (event) => {
    const card = document.getElementById('card-container');
    const cardWidth = card.offsetWidth;
    const deltaX = event.movementX;
    const startY = event.clientY;
    const deltaY = event.clientY - startY;
    card.style.transform = `translateX(${deltaX}px) translateY(${deltaY}px) rotate(${(deltaX / cardWidth) * 15}deg)`;

    if (deltaX > 100) {
      handleSwipeRight();
    } else if (deltaX < -100) {
      handleSwipeLeft();
    }
  };

  const isCardBookmarked = () => {
    const currentCard = getCurrentCard();
    return currentCard && bookmarkedCards.includes(currentCard.id);
  };

  const handleBookmark = () => {
    const currentCard = getCurrentCard();
    if (currentCard) {
      onBookmark(currentCard.id);
      setIsBookmarked(!isBookmarked);
    }
  };

  const handleCardMouseUp = () => {
    document.removeEventListener('mousemove', handleCardMouseMove);
    document.removeEventListener('mouseup', handleCardMouseUp);
    document.removeEventListener('mouseleave', handleCardMouseUp);
    document.body.style.cursor = 'grab';
    const card = document.getElementById('card-container');
    card.style.transform = '';
  };

  const getCurrentCard = () => {
    if (currentCardIndex < options.length) {
      return options[currentCardIndex];
    } else {
      return null;
    }
  };

  return (
    <div>
      {getCurrentCard() ? (
        <CardContainer
          id="card-container"
          onMouseDown={handleCardMouseDown}
          onTouchStart={handleCardTouchStart}
        >
          <div>
            
          
            <CardContentContainer>
              <h2>{getCurrentCard().title}</h2>
              <p>{getCurrentCard().description}</p>
            </CardContentContainer>
          </div>
        </CardContainer>
      ) : (
        <h2>No more cards left!</h2>
      )}
      <ButtonsContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSwipeLeft}
          disabled={swipedCards.length === 0}
        >
          Swipe Left
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSwipeRight}
        >
          Swipe Right
        </Button>
        <Button
              variant={isCardBookmarked() ? 'outlined' : 'contained'}
              color="secondary"
              onClick={handleBookmark}
              style={{ marginTop: '16px' }}
            >
              {isCardBookmarked() ? 'Remove Bookmark' : 'Add Bookmark'}
            </Button>
      </ButtonsContainer>
    </div>
  );
};

export default TinderCard;
