import React from 'react';
import Typography from '@mui/material/Typography'
import './Footer.css';
import { Box, Container, Grid } from '@mui/material';



function Footer() {

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "lightgrey",
        paddingTop: "2rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="black" variant="h7">
              &copy; {new Date().getFullYear()} |Coaching Cards
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle1" fontSize={12}>
              <a style={{ textDecoration: 'none', color: 'unset' }} rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">This work is licensed under a Creative Commons Attribution - NonCommercial - ShareAlike 4.0 International License</a >
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;