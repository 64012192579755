import React from "react";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";

export default function TopNav() {

  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    console.log('click open');
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    console.log('click close');

  };

  const handleNavMenuButton = (url) => {
    setAnchorElNav(null);
    console.log('top nav clicked');
    navigate('/');
  };

  const handleBookMarkedMenuButton = (url) => {
    setAnchorElNav(null);
    console.log('bookmarked clicked');
    navigate('/BookmarkedCards');
  };

  

  return (

    <AppBar position="relative">
      <Container maxWidth="sl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            COACHING CARDS
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="Home"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem key='Flash Card' onClick={handleCloseNavMenu} >
                  <NavLink to='/' style={{ textDecoration: 'none', color: 'unset' }}> <Typography textAlign="center">Flash Card</Typography></NavLink>
                </MenuItem>      
                <MenuItem key='Bookmark Cards' onClick={handleCloseNavMenu} >
                  <NavLink to='/BookmarkedCards' style={{ textDecoration: 'none', color: 'unset' }}> <Typography textAlign="center">Bookmarked Cards</Typography></NavLink>
                </MenuItem>    
                <MenuItem key='About' onClick={handleCloseNavMenu} >
                  <NavLink to='/' style={{ textDecoration: 'none', color: 'unset' }}> <Typography textAlign="center">About</Typography></NavLink>
                </MenuItem>    
                <MenuItem key='SwipeCard' onClick={handleCloseNavMenu} >
                  <NavLink to='/SwipeCard' style={{ textDecoration: 'none', color: 'unset' }}> <Typography textAlign="center">SwipeCard</Typography></NavLink>
                </MenuItem>                                                         

            </Menu>
          </Box>

          <Typography
            variant="h7"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            COACHING CARDS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>            
            <Button key ='Flash Card' onClick={handleNavMenuButton} sx={{ my: 2, color: 'white', display: 'block' }}>Flash Card</Button>
            <Button key ='Bookmark' onClick={handleBookMarkedMenuButton} sx={{ my: 2, color: 'white', display: 'block' }}>Bookmarked Cards</Button>
            <Button key ='About' onClick={handleNavMenuButton} sx={{ my: 2, color: 'white', display: 'block' }}>About</Button>
            <Button key ='SwipeCard' onClick={handleNavMenuButton} sx={{ my: 2, color: 'white', display: 'block' }}>SwipeCard</Button>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>

  );
}
