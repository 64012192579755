import React from 'react';
import Spinner from "../images/spinner2.gif";
import coachingcardimage5 from "../images/cards/5.jpg";
import { Grid, Typography } from '@mui/material';
import PageLayout from '../components/PageLayout';
import CoachingCard from './coachingcard/CoachingCard';
import useFetch from '../useFetch';
import Cookies from 'js-cookie';

export default function BookmarkedCards(props) {

  let bookmarkedCardIds = [];
  try {
    //console.log(Cookies.get('Coachingcards.ie - Bookmarked Cards'));
    bookmarkedCardIds = Cookies.get('Coachingcards.ie - Bookmarked Cards').split(',');
    //console.log(CookieValue2);
  }
  catch (e) {
    console.log('woops');
  }
  finally {
  }


  //get the location of the json for the cards
  const host = "http://" + window.location.host + "/SampleCards.txt";
  const { data, loading, error } = useFetch(host);
  if (loading) return <div align="centre"><img src={Spinner}
    style={{ width: '100px', margin: 'auto', marginTop: '50px', display: 'block' }}
    alt="Loading..." /></div>;
  if (error) console.log(error);


  return (
    <PageLayout>
      <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
        Your Bookmarked Cards
      </Typography>
      <Typography variant="h6" align="center" color="textSecondary" paragraph>
        Here is a list of all the cards you have bookmarked
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        (Bookmarks can be removed on the flash card screen)
      </Typography>
      <div >
      </div>
      <div >        
          <Grid container xs={12} justify="space-between" alignItems="center">            
              {data.groups.map((group, groupIndex) => (
                <>
                  {group.options.map((option, optionIndex) => (
                    <>
                      {option.cards.filter((card) => bookmarkedCardIds.includes(String(card.cardid))).map((card, cardIndex) => (
                        <Grid item xs={12} sx={6} md={4} lg={3}>
                        <div align="center">                          
                          <CoachingCard bookmarked='primary' cardTitle={group.name} cardSubTitle={option.name} cardQuestion={card.question} cardImage={coachingcardimage5} twitter={card.twitterlink} website={card.websitelink} />
                        </div>
                        </Grid>
                      ))}
                    </>
                  ))}
                </>
              ))}            
          </Grid>      
      </div>
    </PageLayout>
  );
};

