
import '../../App.css';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BookmarkIcon from '@mui/icons-material/Bookmark';

import { Language } from '@mui/icons-material';

import TwitterIcon from '@mui/icons-material/Twitter';
import React from 'react';
import Cookies from 'js-cookie';


let CookieValue = [];
try {
  //console.log(Cookies.get('Coachingcards.ie - Bookmarked Cards'));
  CookieValue = Cookies.get('Coachingcards.ie - Bookmarked Cards').split(',');
 // console.log(CookieValue);
}
catch (e) {
 // console.log('woops');
  const tempArray = [10001, 10002];
  Cookies.set('Coachingcards.ie - Bookmarked Cards', tempArray, { expires: 365 });
}
finally {

}






const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



export default function CoachingCard(props) {


//  console.log('props.bookmarked' + props.bookmarked);
  var [bookmarkedColour, setBookmarkedColour] = React.useState(props.bookmarked);
  const [expanded, setExpanded] = React.useState(false);

 // console.log('bookmark props: ' + props.bookmarked);
  if (props.cardLoadState === 0) {
    bookmarkedColour = '';
  }
 // console.log('cardLoadState : ' + cardLoadState);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  
  const bookmarkClick = () => {

   // console.log('bookmark clicked' + bookmarkedColour);
   // console.log('bookmark clicked' + props.bookmarked);
    if (bookmarkedColour === '') {
      setBookmarkedColour('primary');

      var newArray = CookieValue.slice();
      newArray.push(props.cardId);
      CookieValue = newArray;
    //  console.log('new array is  :' + CookieValue);
      Cookies.set('Coachingcards.ie - Bookmarked Cards', newArray, { expires: 365 });


    }
    else {
      const newArray = CookieValue.filter(number => number !== props.cardId);
      Cookies.set('Coachingcards.ie - Bookmarked Cards', newArray, { expires: 365 });
      CookieValue = newArray;
     // console.log('new array is  :' + CookieValue);
      setBookmarkedColour('');
    }

  };

  return (
    <div style={{padding:'2rem' }}>
    <Card sx={{ maxWidth: 350, minWidth: 300 }} className='Card'>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: green[500] }} aria-label="C">
            C
          </Avatar>
        }
        action={
          <IconButton aria-label="bookmark" onClick={bookmarkClick}><BookmarkIcon color={bookmarkedColour} /></IconButton>
        }
        title={props.cardTitle}
        subheader={props.cardSubTitle}
      />
      <CardMedia
        component="img"
        height="174"
        image={props.cardImage}
        alt="Image"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" fontSize='1.2rem' >
          {props.cardQuestion}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <a href={props.twitter} target="_blank" rel="noreferrer">
          <IconButton aria-label="add to favorites">
            <TwitterIcon />
          </IconButton>
        </a>
        <a href={props.website} target="_blank" rel="noreferrer">
          <IconButton aria-label="website" >
            <Language />
          </IconButton>
        </a>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>When would you use this:</Typography>
          <Typography paragraph>
            {props.cardDetail}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </div>

  );
}