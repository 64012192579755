import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function GroupedSelect(props) {
  const [option, setOption] = useState(props.optionSelected);
  //console.log('otion in group selected set to '+option);

  const groupChangeHandler = (event) => {
    setOption(event.target.value);
    props.onChangeSelection(event.target.value);
    //console.log('otion in group selected on change'+event.target.value);
  }

  return (
   
      <div>
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel htmlFor="grouped-native-select">Coaching Models</InputLabel>
          <Select native defaultValue={option} id="grouped-native-select" label="Coaching Models" onChange={groupChangeHandler}>
           
            <optgroup label="Grow Coaching Model">
              <option value={1}>GROW - Goal Setting</option>
              <option value={2}>GROW - Current Reality</option>
              <option value={3}>GROW - Options</option>
              <option value={4}>GROW - Will (what will you do)</option>
            </optgroup>
            <optgroup label="Agile Coaching">
              <option value={5}>Team-Work</option>
              <option value={6}>Delays</option>
              <option value={7}>One to One Agile Coaching</option>
            </optgroup>
            <optgroup label="Couples Coaching">
              <option value={8}>Couples Coaching</option>
            </optgroup>
            <optgroup label="Parent Coaching">
              <option value={9}>Parent Coaching</option>
            </optgroup>  
            <optgroup label="7 habits - Coaching Questions">
              <option value={10}>7 habits of highly effective people coaching questions</option>
            </optgroup>  
            <optgroup label="New Manager Coaching Cards">
              <option value={11}>New Manager Coaching Cards</option>
            </optgroup>                                  
            <optgroup label="New Manager Coaching Cards">
              <option value={11}>New Manager Coaching Cards</option>
            </optgroup>   
            <optgroup label="Build emotional intelligence">
              <option value={12}>Build emotional intelligence</option>
            </optgroup>      
            <optgroup label="Build innovation thinking">
              <option value={13}>Build innovation thinking</option>
            </optgroup>                                   
          </Select>
        </FormControl>
      </div>
   
  );
}


