import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from "react-gtm-module";


import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AddCard from './pages/AddCard';
import ErrorPage from './pages/ErrorPage';
import BookmarkedCards from './pages/BookmarkedCards';
import SwipeCard from './pages/coachingcard/SwipeCard';







const tagManagerArgs = {
  
  gtmId: "G-JEVQDMJ1GG"
}

TagManager.initialize(tagManagerArgs)



const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/AddCard",
    element: <AddCard/>
  },
  {
    path: "/ErrorElement",
    element: <ErrorPage/>
  },  
  {
    path: "/BookmarkedCards",
    element: <BookmarkedCards/>
  },   
  {
    path: "/errorElement",
    element: <ErrorPage/>
  },
  {
    path: "/SwipeCard",
    element: <SwipeCard/>
  },
  {
    path: "*",
    element: <ErrorPage/>
  }
  
]
);

ReactDOM.render(

  <React.StrictMode>
    <script type="text/javascript" src="https://coachingcards.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=d7acf05a"></script>
  

      <RouterProvider fallbackElement='ErrorPage' router={router} />

 
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
