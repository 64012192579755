import PageLayout from "../components/PageLayout";


export default function ErrorPage() {
    console.log('Error Page shown');
    return (
        <PageLayout>
            Something went wrong
        </PageLayout>
    );
}